<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('withdrawalRequest.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <!--    <base-detail-action-button-->
    <!--      :permissions="permissions"-->
    <!--      :basePath="basePath"-->
    <!--      :id="detail.id"-->
    <!--      v-if="detail.id"-->
    <!--      @deleteAction="deleteAction"-->
    <!--    >-->
    <!--    </base-detail-action-button>-->
    <div class="mt-6">
      {{ $t('withdrawalRequest.withdrawalDetail') }}
    </div>
    <hr class="mt-2 mb-4" />
    <v-row>
      <v-col cols="6"> ID </v-col>
      <v-col cols="6">
        {{ detail.id }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('withdrawalRequest.createdAt') }}
      </v-col>
      <v-col cols="6">
        {{
          detail.created_at != null ? format(new Date(detail.created_at), 'dd MMMM yyyy HH:mm') : ''
        }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('withdrawalRequest.id') }}
      </v-col>
      <v-col cols="6">
        {{ detail.id }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('withdrawalRequest.agentName') }}
      </v-col>
      <v-col cols="6">
        {{ detail.agent_name }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('withdrawalRequest.phone') }}
      </v-col>
      <v-col cols="6">
        {{ detail.phone_number }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6"> Agen sudah dihapus? </v-col>
      <v-col cols="6">
        {{ detail.agent_deleted ? 'Ya' : 'Tidak' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('withdrawalRequest.amount') }}
      </v-col>
      <v-col cols="6">
        {{ detail.amount }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('withdrawalRequest.status') }}
      </v-col>
      <v-col cols="6">
        {{ detail.status }}
      </v-col>
    </v-row>
    <div class="mt-6">
      {{ $t('withdrawalRequest.withdrawalDestinationDetail') }}
    </div>
    <hr class="mt-2 mb-4" />
    <v-row>
      <v-col cols="6">
        {{ $t('general.bankName') }}
      </v-col>
      <v-col cols="6">
        {{ detail.bank }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('general.accountHolder') }}
      </v-col>
      <v-col cols="6">
        {{ detail.account_holder }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('general.accountNumber') }}
      </v-col>
      <v-col cols="6">
        {{ detail.account_number }}
      </v-col>
    </v-row>
    <div
      class="d-flex align-center justify-center mt-4"
      v-if="permissions.includes('EDIT') && detail.status !== 'FAIL'"
    >
      <v-btn color="primary" class="bottom--button" @click="openWithdrawalForm">
        {{
          detail.status === 'PENDING'
            ? $t('withdrawalRequest.btn.withdraw')
            : $t('withdrawalRequest.btn.seeProof')
        }}
      </v-btn>
    </div>
    <div
      class="d-flex align-center justify-center mt-4"
      v-if="permissions.includes('EDIT') && detail.status === 'PENDING'"
    >
      <v-btn color="error" class="bottom--button" @click="showReject = true">
        {{ $t('withdrawalRequest.btn.reject') }}
      </v-btn>
    </div>
    <v-dialog v-model="showReject" max-width="330">
      <v-card>
        <v-card-title>
          {{ $t('withdrawalRequest.rejectConfirm') }}
          <v-spacer />
          <v-icon aria-label="Close" @click="showReject = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn color="error" text @click="showReject = false">
            {{ $t('general.no') }}
          </v-btn>
          <v-btn color="primary" text @click="reject">
            {{ $t('general.yes') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  data() {
    return {
      format,
      showReject: false,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.withdrawalRequest.basePath,
    }),
  },
  methods: {
    async openWithdrawalForm() {
      this.$store.commit('withdrawalRequest/SET_RECEIPT_PHOTO', null);
      this.$modal.show('modal-withdrawal', {
        withdrawalRequest: this.detail,
      });
    },
    async reject() {
      try {
        await this.$store.dispatch('withdrawalRequest/reject');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('withdrawalRequest.reject.successMsg'),
          'success',
        );
        this.showReject = false;
        await this.$store.dispatch('withdrawalRequest/getDetailInitData', this.$route.params.id);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
